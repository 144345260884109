import React from 'react';
import { compose } from 'recompose';

import Layout from '../components/layout';
import {
  withAuthorization,
} from '../components/Session';
import Forms from '../components/Forms';
import * as ROLES from '../constants/roles';
import Navigation from '../components/Navigation';

const AddFormsPageBase = () => (
  <div>
    <Navigation />

    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">
          Forms
        </h1>
        <p>
          The Forms Page is accessible to every signed in admin user.
        </p>
      </div>
    </header>

    <main>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className='sm:w-full w-11/12 mx-auto'>
          <div className='sm:w-full w-1/2 mt-12'>
            <h2 className='text-lg mb-2 font-bold'>Add New Forms</h2>

            <Forms />
          </div>
        </div>
      </div>
    </main>
  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const AddFormsPage = compose(
  withAuthorization(condition),
)(AddFormsPageBase);

export default () => (
  <Layout>
    <AddFormsPage />
  </Layout>
);
