import React, { Component } from 'react';
import { Link, navigate } from 'gatsby'

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class Forms extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      company: '',
      paperformHire: '',
      paperformTerm: '',
      loading: false,
      forms: [],
      limit: 20,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.onListenForForms();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForForms = () => {
    this.setState({ loading: true });

    this.props.firebase
      .forms()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const formObject = snapshot.val();

        if (formObject) {
          const formList = Object.keys(formObject).map(key => ({
            ...formObject[key],
            uid: key,
          }));

          this.setState({
            forms: formList,
            loading: false,
          });
        } else {
          this.setState({ forms: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.forms().off();
  }

  onChangeCompany = event => {
    this.setState({ company: event.target.value });
  };

  onChangeHire = event => {
    this.setState({ paperformHire: event.target.value });
  };

  onChangeTerm = event => {
    this.setState({ paperformTerm: event.target.value });
  };

  onCreateForm = (event, authUser) => {
    this.props.firebase.forms().push({
      company: this.state.company,
      paperformHire: this.state.paperformHire,
      paperformTerm: this.state.paperformTerm,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({
      company: '',
      paperformHire: '',
      paperformTerm: '',
    });

    navigate(ROUTES.FORMS);

    event.preventDefault();
  };

  onEditForm = (form, company, paperformHire, paperformTerm) => {
    const { uid, ...formSnapshot } = form;

    this.props.firebase.form(form.uid).set({
      ...formSnapshot,
      company,
      paperformHire,
      paperformTerm,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveForm = uid => {
    this.props.firebase.form(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForForms,
    );
  };

  render() {
    const { company, paperformHire, paperformTerm } = this.state;

    const isInvalid =
      company === '' ||
      paperformHire === '' ||
      paperformTerm === ''

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <form
              onSubmit={event =>
                this.onCreateForm(event, authUser)
              }
              className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'
            >
              <div className="mb-4">
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                  Company Name <span className='font-normal'>(Required)</span>
                </label>

                <input
                  aria-label='company'
                  type="text"
                  value={company}
                  onChange={this.onChangeCompany}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </div>

              <div className="mb-4">
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="paperformHire">
                  Paperform ID for New Hire Form <span className='font-normal'>(Required)</span>
                </label>

                <input
                  aria-label='paperformHire'
                  type="text"
                  value={paperformHire}
                  onChange={this.onChangeHire}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </div>

              <div className="mb-4">
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="paperformTerm">
                  Paperform ID for Termination Form <span className='font-normal'>(Required)</span>
                </label>

                <input
                  aria-label='paperformTerm'
                  type="text"
                  value={paperformTerm}
                  onChange={this.onChangeTerm}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </div>

              <button disabled={isInvalid} type="submit" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>
                Save
              </button>
            </form>

            <Link to='/forms'>
              <button
                type="button"
                className="group relative w-full flex justify-center py-2 px-4 my-6 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3"
              >
                Back to Forms Dashboard
              </button>
            </Link>

          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Forms);
